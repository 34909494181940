import React from "react";
import blogData from "./resources/blog_entries/data";
import { Grid, List, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class Blog extends React.Component {
  render() {
    return (
      <Grid>
        {blogData
          .map((item, index) => {
            return (
              <Grid.Row centered columns={2}>
                <Grid.Column textAlign="left">
                  <List>
                    <List.Item>
                      <div className="blog-list-title">
                        <Link to={`/blog/${item.id}`}>{item.title}</Link>
                      </div>
                    </List.Item>
                    <List.Item>
                      <Container text>{item.description}</Container>
                    </List.Item>
                  </List>
                  <List.Item>
                    <Container text>{item.date}</Container>
                  </List.Item>
                </Grid.Column>
              </Grid.Row>
            );
          })
          .reverse()}
      </Grid>
    );
  }
}
