import React from "react";
import "./BlogView.css";
import { useParams } from "react-router-dom";
import Blog0 from "./resources/blog_entries/Blog0";
import Blog1 from "./resources/blog_entries/Blog1";
import blogData from "./resources/blog_entries/data";

const BlogView = function BlogViewFromRoute() {
  let { blogid } = useParams();
  var blog_metadata = blogData[blogid];

  return (
    <div>
      <div className="blog-view">
        <div className="blog-title">
          {blog_metadata.title}
          <br></br>
        </div>
        <div className="blog-view-description">
          {blog_metadata.description}
          <br></br>
        </div>
        <div className="blog-date">{blog_metadata.date}</div>
        <div className="blog-body">
          {parseInt(blogid) === 0 ? (
            <Blog0 />
          ) : parseInt(blogid) === 1 ? (
            <Blog1 />
          ) : null}
          <blog_object />
        </div>
      </div>
    </div>
  );
};

export default BlogView;
