import React from "react";
import { Menu, Button, Image } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import headshot from "./images/headshot_mobile.png";

class TeddyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_open: false,
    };
  }

  render() {
    return (
      <Menu size="large" text fluid widths={6}>
        <Menu.Item>
          <Button onClick={() => (window.location = "/")}> About Me </Button>
        </Menu.Item>
        <Menu.Item>
          <Image src={headshot} size="small" circular={true} />
        </Menu.Item>
        <Menu.Item>
          <Button onClick={() => (window.location = "/blog")}>Blog</Button>
        </Menu.Item>
      </Menu>
    );
  }
}

export default withRouter(TeddyHeader);
