import React from "react";
import { Container } from "semantic-ui-react";

function Bio() {
  return (
    <Container text textAlign="center">
      duke computer science and statistics graduate <br></br>
      sports fan <br></br>
      using code to make a positive impact <br></br>
    </Container>
  );
}

export default class About extends React.Component {
  render() {
    return <Bio />;
  }
}
