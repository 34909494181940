import React from "react";

const Blog0 = () => {
  return (
    <div>
      <div className="blog-body-text">
        Hello! I am Teddy Marchildon. I recently graduated from Duke University
        with a B.S. in Computer Science and minor in Statistics. I am from the
        Denver area, and I will be moving out to San Francisco soon to start as
        a Software Engineer at Facebook. I am very excited to get out there and
        start working!
      </div>

      <br></br>

      <div className="blog-body-text">
        I built this website in some free time during the last couple months of
        college. The blog is entirely my own code, from the Hack server side
        code (thanks Facebook for getting me started on Hack!) and the
        postgreSQL database to store the blogs. I built it as more of an API
        rather than embedded application, so that perhaps I can consume it with
        a mobile app or something, who knows.
      </div>

      <br></br>

      <div className="blog-body-text">
        The website comes complete with an admin portal for myself to create new
        blogs. Since the blogs are saved as straight html, I have an authoring
        environment that lets me type in html, and I see what it will look like
        in my blog right next to it - thanks to React state management! This
        made it super easy to create the admin portal and authoring environment.
      </div>

      <br></br>

      <div className="blog-body-text">
        Anyway, I’m glad you found your way here, and I will post more about my
        endeavors with Computer Science! This was a fun little project that I
        will surely expand more.
      </div>

      <br></br>

      <div className="blog-body-text">Teddy</div>
    </div>
  );
};

export default Blog0;
