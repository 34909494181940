import "./App.css";
import TeddyHeader from "./TeddyHeader";
import Resume from "./Resume";
import About from "./About";
import Blog from "./Blog";
import BlogView from "./BlogView";
import { Container } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const AboutSection = (
  <Container>
    <TeddyHeader />
    <About />
    <Resume />
  </Container>
);

const BlogSection = (
  <Container>
    <TeddyHeader />
    <Blog />
  </Container>
);

const BlogViewSection = (
  <Container>
    <TeddyHeader />
    <BlogView />
  </Container>
);

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact={true} path="/about">
            {AboutSection}
          </Route>
          <Route exact={true} path="/blog">
            {BlogSection}
          </Route>
          <Route exact path="/blog/:blogid">
            {BlogViewSection}
          </Route>
          <Route exact={true} path="/">
            {AboutSection}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
