import React from "react";
import { Grid, Image, List, Header, Container } from "semantic-ui-react";
import resume from "./resources/resume";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
    return true;
  });
  return images;
}

const images = importAll(
  require.context("./images", false, /\.(png|jpe?g|svg)$/)
);

const extractData = function (item, i, invert) {
  return (
    <ResumeItem
      key={item.img_url}
      image_url={images[item.img_url]}
      company={item.company}
      title={item.title}
      date={item.date}
      location={item.location}
      description={item.description}
      link={item.link}
      invert={invert}
    />
  );
};

class ResumeItem extends React.Component {
  render() {
    const infoColumn = (
      <Grid.Column textAlign="left" width={6}>
        <List>
          <List.Item>
            <Header>{this.props.company}</Header>
          </List.Item>
          <List.Item>
            <Header as="h4">{this.props.title}</Header>
          </List.Item>
          <List.Item>
            <Header as="h5">{this.props.location}</Header>
          </List.Item>
          <List.Item>
            <Container text fluid>
              {this.props.description}
            </Container>
          </List.Item>
        </List>
      </Grid.Column>
    );
    const imageColumn = (
      <Grid.Column width={3} textAlign="left">
        <Image src={this.props.image_url.default} size="small" />
      </Grid.Column>
    );
    return (
      <Grid.Row columns={2} centered verticalAlign="middle" stretched={false}>
        {this.props.invert
          ? [infoColumn, imageColumn]
          : [imageColumn, infoColumn]}
      </Grid.Row>
    );
  }
}

export default class Resume extends React.Component {
  render() {
    const workItems = resume.work.map((item, index) => {
      return extractData(item, index, false);
    });
    const projectItems = resume.projects.map((item, index) => {
      return extractData(item, index, true);
    });
    return (
      <div>
        <div className="teddy-resume">
          <Header centered as="h2">
            my work so far
          </Header>
          <Grid>{workItems}</Grid>
        </div>
        <div className="other-projects">
          <Header centered as="h2">
            other projects
          </Header>
          <Grid>{projectItems}</Grid>
        </div>
      </div>
    );
  }
}
